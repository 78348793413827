















































































































































































































































































































































































































































































































































































































































































import { Api } from "@/models/class/api.class";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import {
  ALLOWED_EXCEL_FILE,
  ALLOWED_IMAGE_FILE,
} from "@constant/upload.constant";
import { Messages } from "@/models/enums/messages.enum";
import {
  IArDataPayload,
  IArDataResponse,
  IReceiptLine,
  WriteOffDTO,
} from "@interface/account-receivables.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { arService } from "@service/ar.service";
import { settingsServices } from "@/services/settings.service";
import {
  isDateAfterMaxDate,
  formatterNumber, reverseFormatNumber
} from "@/validator/globalvalidator";
import moment from "moment";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { RECEIPT_TYPE } from "./ReceiptTypeSelect.vue";
import { ResponseAccountingCurrency } from "@/models/interface-v2/currency.interface";
import { ResponsePreference } from "@/models/interface-v2/preferences.interface";
import { accountingCurrencyService } from "@/services-v2/accounting-currency.service";
import { ContactData, IAddressDataList } from "@/models/interface-v2/contact.interface";
import { IOption } from "@/models/interface-v2/common.interface";
import { MAXIMUM_FILE_SIZE } from "@/models/constant/global.constant";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Decimal } from "decimal.js-light";
import { fileServices } from "@/services-v2/file.service";
import { transformDataURL } from "@/helpers/file-reader";
import { Mode } from "@/models/enums/global.enum";


enum RECEIPT_METHOD {
  CASH = "CASH",
  BANK_TRANSFER = "BANK_TRANSFER",
  CHEQUE = "CHEQUE",
}

export enum STATUS {
  NEW = "New",
  SUBMITTED = "Submitted",
  POSTED = "Posted",
  VOID = "Void",
  APPROVED = "Approved",
  UPDATED = "Updated",
  CANCELED = "Canceled",
  REJECTED = "Rejected",
}

enum InvoiceType {
  INVOICE_AR = "Invoice AR",
  CREDIT_MEMO = "Credit Memo",
  PREPAYMENT = "Prepayment",
}

export const DISALLOW_EDIT: string[] = [
  STATUS.APPROVED,
  STATUS.CANCELED,
  STATUS.REJECTED,
];

@Component({
  name: "ReceiptArForm",
  watch: {
    currencyFrom: {
      immediate: true,
      deep: true,
      handler(newVal) {
        const params:RequestQueryParamsModel = {
          search: `fromCurrency.currencyCode~${newVal}_AND_toCurrency.currencyCode~${this.currencyTo}`
        };
        settingsServices
          .listOfCurrency(params, "")
          .then((response) => {
            if(response.data[0]) {
              this.receiptArForm.currencyRate = response.data[0].rate;
            } else if (newVal === this.currencyTo) {
              this.receiptArForm.currencyRate = 1;
            } else {
              this.receiptArForm.currencyRate = 0;
            }
          });
      }
    },
    invoiceSource: {
      immediate: true,
      deep: true,
      handler() {
        let longestDate = "";
        this.invoiceSource.forEach((dataForeach, index) => {
          // jika index 0 atau invoice date kurang atau lebih lama dari longestdate saat ini
          if(index === 0 || moment(dataForeach.invoiceDate).isBefore(longestDate)) longestDate = dataForeach.invoiceDate;
        });
        this.longestDate = moment(longestDate).format(DEFAULT_DATE_FORMAT);
      }
    },
  },
  components: {
    CSelectCurrency: () => import(/*webpackPrefetch: true*/"@/components/shared/select-currency/CSelectCurrency.vue"),
    CSelectCustomer: () => import(/*webpackPrefetch: true*/"@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectBillAddress: () => import(/*webpackPrefetch: true*/"@/components/shared/select-bill-address/CSelectBillAddress.vue"),
    CSelectInvoiceReceiptMethod: () => import(/*webpackPrefetch: true*/"@/components/shared/select-invoice-receipt-method/CSelectInvoiceReceiptMethod.vue"),
    CSelectInvoiceARType: () => import(/*webpackPrefetch: true*/"@/components/shared/select-invoice-ar-type/CSelectInvoiceARType.vue"),
    CSelectCompanyBank: () => import(/*webpackPrefetch: true*/"@/components/shared/select-company-bank/CSelectCompanyBank.vue"),
    CSelectInvoice: () => import(/*webpackPrefetch: true*/"./components/CSelectInvoice.vue"),
    CSelectInvoiceCreditMemo: () => import(/*webpackPrefetch: true*/"./components/CSelectInvoiceCreditMemo.vue"),
    ModalWriteOff: () => import(/*webpackPrefetch: true*/"./components/ModalWriteOff.vue"),
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
  },
  mixins: [
    MNotificationVue,
  ],
})
export default class ReceiptArForm extends Vue {
  Api = Api;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  @Ref("form") form;
  longestDate = ""
  receiptArForm: IArDataPayload = {
    invoiceType: undefined as string | undefined | any,
    customerBankName: "",
    branchWarehouseId: undefined as string | undefined | any,
    currencyId: "",

    receiptDate: "",
    customerId: undefined as string | undefined | any,
    customerBillToAddress: undefined as string | undefined | any,
    receiptMethod: undefined as string | undefined | any,
    chequeDate: "",
    chequeNumber: "",
    status: "New",
    receiptType: RECEIPT_TYPE.UNINDENTIFIED,
    receiptAmount: 0,
    currencyRate: 0,
    bankAccountId: undefined as string | undefined | any,
    description: "",
    fileAttachment: [] as string[],
  };
  currencyFrom = "IDR"
  currencyTo = ""
  formRules = {
    branchWarehouseId: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    invoiceType: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    customerBankName: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    currencyId: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    receiptDate: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
      {
        validator: (rule, value, callback) => {
          if (isDateAfterMaxDate(value)) {
            callback(
              this.$t("lbl_message_error_max_date", {
                maxDate: moment().format(DEFAULT_DATE_FORMAT),
              })
            );
            return;
          }

          callback();
        },
        message: (): string => this.$t(Messages.VALIDATION_MAX_DATE, {
          maxDate: moment().format(DEFAULT_DATE_FORMAT),
        }).toString(),
      },
    ],
    receiptMethod: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    receiptAmount: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    chequeDate: [
      {
        required: false,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    chequeNumber: [
      {
        required: false,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    bankAccountId: [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    customerBillToAddress: [
      {
        required: false,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    customerId: [
      {
        required: false,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
    currencyRate: [
      {
        required: false,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ],
  };
  columnsTable = [
    {
      // title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      slots: { title: "invoiceNumberTitle" },
      scopedSlots: { customRender: "invoiceNumber" },
      // responsiveColSelect: [
      //   {
      //     name: "invoiceNumber",
      //     style: "width: 100%;",
      //     disabled: false,
      //     value: "name",
      //     options: [] as any[],
      //     loading: false,
      //   },
      //   {
      //     name: "invoiceCreditMemo",
      //     style: "width: 100%;",
      //     disabled: false,
      //     value: "id",
      //     options: [] as any[],
      //     loading: false,
      //   },
      // ],
      // responsiveColInputNumber: [
      //   {
      //     name: "receiptAmount",
      //     placeholder: this.$t("lbl_receipt_amount"),
      //     style: "width: 100%",
      //     disabled: false,
      //     parser: reverseFormatNumber,
      //     formatter: formatterNumber,
      //   },
      //   {
      //     name: "writeOfAmount",
      //     placeholder: this.$t("lbl_write_off_amount"),
      //     style: "width: 100%",
      //     disabled: false,
      //     parser: reverseFormatNumber,
      //     formatter: formatterNumber,
      //   },
      // ],
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_invoice_amount"),
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_paid_amount"),
      dataIndex: "paidAmount",
      key: "paidAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_write_off_amount"),
      dataIndex: "writeOfAmount",
      key: "writeOfAmount",
      width: 200,
      scopedSlots: { customRender: "writeOfAmount" },
    },
    {
      title: this.$t("lbl_invoice_outstanding"),
      dataIndex: "outstandingInvoice",
      key: "outstandingInvoice",
      width: 200,
      customRender: this.getOutstandingInvoice,
    },
    {
      title: this.$t("lbl_invoice_credit_memo"),
      dataIndex: "invoiceCreditMemo",
      key: "invoiceCreditMemo",
      width: 200,
      scopedSlots: { customRender: "invoiceCreditMemo" },
    },
    {
      title: this.$t("lbl_credit_amount"),
      dataIndex: "creditAmount",
      key: "creditAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_receipt_amount"),
      dataIndex: "receiptAmount",
      key: "receiptAmount",
      width: 200,
      scopedSlots: { customRender: "receiptAmount" },
    },
    {
      title: this.$t("lbl_action"),
      dataIndex: "operation",
      width: "120px",
      align: "center",
      scopedSlots: { customRender: "operation" },
      fixed: "right",
      button: ["delete"],
    },
  ];
  invoiceSource = [] as IReceiptLine[];
  disable = {
    form: false,
    addRowBtn: true,
    update: false,
    editableField: false,
  };
  showFields = {
    cheque: false,
    rate: false,
  };
  ALLOWED_FILE_SIZE = MAXIMUM_FILE_SIZE;
  ALLOWED_FILE_TYPES = ALLOWED_IMAGE_FILE.concat(ALLOWED_EXCEL_FILE, [
    ".pdf",
  ]).join();
  show = {
    updateBtn: false,
  };
  id = "" as string;
  arReceiptDetail = {} as IArDataResponse;
  loadingIndicator = {
    data: false,
    submit: false,
    void: false,
    approved: false,
    updated: false,
    canceled: false,
    print: false,
    reject: false,
  };
  headers = {
    authorization: "Bearer " + this.$store.state.access_token,
  };
  fileList = [] as any[];
  modalOpen = false
  dataSourceModal = []
  columnsTableModal = [
    {
      title: "Hello World",
      dataIndex: "hello",
      key: "hello",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    }
  ]
  customerBillAddress: IAddressDataList[] = []
  dtOpt = {
    invoiceNumber: [] as IOption[],
    creditMemo: [] as IOption[],
  }
  deletedRow: IReceiptLine[] = []

  linkAttachment: { thumbnail, filename }[] = []
  modal = {
    writeOff: {
      show: false,
      data: {} as any,
    }
  }

  created(): void {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getArReceiptDetail();
    }
  }

  onSaveWriteOff({ value, deletedRow }: { value: WriteOffDTO[], deletedRow: string[] }): void {
    const idx = this.modal.writeOff.data.index;
    this.invoiceSource[idx].writeOffRequestDTOS = [...value];
    this.invoiceSource[idx].writeOffAmountTotal = value.reduce((a, b) => new Decimal(b.amount ?? 0).plus(a).toNumber(), 0);
    this.invoiceSource[idx].deletedWriteOffIds = deletedRow;
    this.total.writeOff = this.invoiceSource
      .reduce((a, b) => new Decimal(b.writeOffAmountTotal ?? 0).plus(a).toNumber(), 0);
  }

  showWriteoffDetail(r: IReceiptLine): void {
    const { key } = r;
    this.modal.writeOff.show = true;
    this.modal.writeOff.data = {...r, index: key};
  }

  disabledDate(current): boolean {
    // Can not select days before invoice date in table
    return current < moment(this.longestDate, DEFAULT_DATE_FORMAT);
  }

  @Watch("appPreference", {
    deep: true,
    immediate: true,
  })
  async setBaseCurrencyCreate(): Promise<void> {
    try {
      const tempObject = {
        feature_base_currency: this.appPreference.find(x => x.key === "feature_based_currency")?.value || "",
      };
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if(tempObject.feature_base_currency) {
        params.search = `secureId~${tempObject.feature_base_currency}`;
        const { data } = await accountingCurrencyService.listOfMasterCurrency(params);
        if (!data.length) return;
        this.currencyTo = data[0].currencyCode as string;
      }
    } catch (error) {
      this.showErrorMessage("notif_process_fail");
    }
  }

  handleCancel(): void {
    this.modalOpen = false;
  }

  saveDataModal(): void {
    // console.log("Save");
  }

  handleReceiptMethodChange(value: string): void {
    if (value.toUpperCase() === RECEIPT_METHOD.CHEQUE.toUpperCase()) {
      this.showFields.cheque = true;
      this.formRules.chequeDate = [
        {
          required: true,
          message: (): string => this.$t("lbl_validation_required_error").toString(),
        },
      ];
      this.formRules.chequeNumber = [
        {
          required: true,
          message: (): string => this.$t("lbl_validation_required_error").toString(),
        },
      ];
    } else {
      this.showFields.cheque = false;
      this.formRules.chequeDate = [
        {
          required: false,
          message: (): string => this.$t("lbl_validation_required_error").toString(),
        },
      ];
      this.formRules.chequeNumber = [
        {
          required: false,
          message: (): string => this.$t("lbl_validation_required_error").toString(),
        },
      ];
    }
  }

  handleAddRow(): void {
    if (
      !this.receiptArForm.branchWarehouseId ||
      !this.receiptArForm.customerId ||
      !this.receiptArForm.currencyId
    ) {
      this.showNotifError("lbl_fill_branch_customer_typeinvoice_currency");
      return;
    }
    this.invoiceSource = [
      ...this.invoiceSource,
      {
        no: this.invoiceSource.length + 1,
        key: this.invoiceSource.length,
        invoiceARId: "",
        createdDate: "",
        invoiceNumber: "",
        invoiceOutstanding: 0,
        invoiceDate: "",
        invoiceAmount: 0,
        paidAmount: 0,
        receiptAmount: 0,
        invoiceCreditMemo: "",
        idApplyPrepayment: "",
        applyPrepayment: {
          prepaymentLines: [],
          deletedPrepaymentLineIds: [],
        }
      },
    ];
  }

  responseDeleteRow(row): void {
    this.invoiceSource.splice(row.key, 1);
    this.invoiceSource.forEach((d, idx) => {
      d.key = idx;
      d.no = idx + 1;
    });
    this.deletedRow.push(row);
  }

  onSelectInvoiceNumber(_e, meta, row): void {
    this.receiptArForm.chequeDate = "";
    const invoiceAmount = this.receiptArForm.invoiceType === InvoiceType.INVOICE_AR ? meta?.invoiceAmount || 0 : meta?.amount || 0;
    row.invoiceARId = meta.id;
    row.receiptAmount = meta.total || 0;
    row.writeOfAmount = 0;
    row.invoiceDate = meta.invoiceDate || "";
    row.paidAmount = meta.paidAmount || 0;
    row.invoiceAmount = invoiceAmount;
    row.invoiceOutstanding = this.calculateOutstandingInvoice(
      invoiceAmount || 0,
      meta.paidAmount || 0,
      0,
    );
    row.invoice = meta;
  }

  onSelectCreditMemo(e, meta, row): void {
    row.creditAmount = meta.amount;
    row.meta.creditMemo = meta;
  }

  handleBack(): void {
    this.$router.push({ name: "accountreceivables.ar.receipt" });
  }

  handlePrint(): void {
    this.loadingIndicator.print = true;
    this.loadingIndicator.print = false;
  }

  onSubmit(type: STATUS): void {
    try {
      this.form.validate(async (valid) => {
        if (!valid) {
          this.showErrorMessage("lbl_validation_required_error");
          throw new Error("Empty mandatory fields");
        }
        const isError = !this.checkError();
        if (isError) {
          throw isError;
        }
        const payload = this.prepareObject();
        let response = {} as IArDataResponse;

        switch (type) {
          case STATUS.VOID:
            this.loadingIndicator.void = true;
            response = await arService.voidArReceipt(payload, this.id);
            break;
          case STATUS.APPROVED:
            this.loadingIndicator.approved = true;
            response = await arService.approveArReceipt(payload, this.id);
            break;
          case STATUS.UPDATED:
            if(this.disable.update) {
              this.disable.update = false;
              this.invoiceSource = this.invoiceSource.map((dataMap) => {
                return { ...dataMap, disabledInput: false };
              });
            } else if (!this.disable.update) {
              this.loadingIndicator.updated = true;
              response = await arService.updateArReceipt(payload, this.id);
            }
            break;
          case STATUS.CANCELED:
              this.loadingIndicator.canceled = true;
            response = await arService.cancelArReceipt(payload, this.id);
            break;
          default:
            break;
        }
        if(Object.keys(response).length > 0) {
          this.showNotifSuccess("notif_success_create_ar_receipt", { document: response?.documentNumber});
          this.handleBack();
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingIndicator.void = false;
      this.loadingIndicator.approved = false;
      this.loadingIndicator.updated = false;
      this.loadingIndicator.canceled = false;
    }
  }

  handleSubmit(): void {
    try {
      this.loadingIndicator.submit = true;
      this.form.validate(async (valid: boolean) => {
        if (!valid) {
          this.showErrorMessage("lbl_validation_required_error");
          throw new Error("Empty mandatory fields");
        }
        const isError = !this.checkError();
        if (isError) {
          throw isError;
        }
        const payload = this.prepareObject();
        if (this.id) {
          if (
            this.receiptArForm.receiptType === RECEIPT_TYPE.UNINDENTIFIED &&
            this.receiptArForm.status === STATUS.NEW
          ) {
            this.updateArReceipt(payload, this.id);
          } else if (
            this.arReceiptDetail.receiptType === RECEIPT_TYPE.UNINDENTIFIED &&
            this.receiptArForm.receiptType === RECEIPT_TYPE.APPLIED
          ) {
            this.applyArReceipt(payload, this.id);
          }
        } else {
          this.createArReceipt(payload);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingIndicator.submit = false;
    }
  }

  async updateArReceipt(payload: IArDataPayload, id: string): Promise<void> {
    try {
      const response = await arService.updateArReceipt(payload, id);
      this.showNotifSuccess("notif_success_update_ar_receipt", { document: response?.documentNumber });
      this.handleBack();
    } catch (error) {
      console.error(error);
    }
  }

  async applyArReceipt(payload: IArDataPayload, id: string): Promise<void> {
    try {
      const response = await arService.applyArReceipt(payload, id);
      this.showNotifSuccess("notif_success_apply_ar_receipt", { document: response?.documentNumber });
      this.handleBack();
    } catch (error) {
      console.error(error);
    }
  }

  async createArReceipt(payload: IArDataPayload): Promise<void> {
    try {
      const response = await arService.createArReceipt(payload);
      this.showNotifSuccess("notif_success_create_ar_receipt", { document: response?.documentNumber });
      this.handleBack();
    } catch (error) {
      console.error(error);
    }
  }

  prepareObject(): IArDataPayload {
    let data = {} as IArDataPayload;
    data = { ...this.receiptArForm };

    if (this.receiptArForm.chequeDate) {
      data.chequeDate = moment(this.receiptArForm.chequeDate).format();
    }
    data.receiptDate = moment(this.receiptArForm.receiptDate).format();
    if (this.receiptArForm.currencyRate && isNaN(+this.receiptArForm.currencyRate)) {
      data.currencyRate = this.receiptArForm.currencyRate;
    }
    if (data.receiptType === RECEIPT_TYPE.APPLIED) {
      data.status = STATUS.SUBMITTED;
    } else {
      data.status = STATUS.NEW;
    }
    data.receiptLines = this.invoiceSource.map((s) => {
      const dataFind = s.meta?.creditMemo ? s.meta.creditMemo : {id: "", documentNumber: "", remainingAmount: 0, description: "" };
      const receipt: IReceiptLine = {
        invoiceARId: this.receiptArForm.invoiceType === InvoiceType.INVOICE_AR ? s.invoiceARId : "",
        invoicePrepaymentId: this.receiptArForm.invoiceType === InvoiceType.PREPAYMENT ? s.invoicePrepaymentId || s.invoiceARId : "" ,
        receiptAmount: s.receiptAmount || 0,
        applyPrepayment: {
          prepaymentLines: dataFind.id || s.idApplyPrepayment ? [
            {
              appliedAmount: +dataFind.remainingAmount,
              description: dataFind.description || "",
              invoicePrepaymentId: dataFind.id,
              id: s.idApplyPrepayment ? s.idApplyPrepayment : ""
            }
          ] : [],
          deletedPrepaymentLineIds: this.deletedRow.map(x => {
            if (x.id) return x.id;
            return "";
          }),
        },
        writeOffRequestDTOS: s.writeOffRequestDTOS,
        deletedWriteOffIds: s.deletedWriteOffIds?.filter(x => x !== "") || [],
      };

      if (this.id) {
        receipt.id = s.id;
      }
      return receipt;
    });
    data.fileAttachment = [...this.receiptArForm.fileAttachment, ...this.fileList.map((d) => d.url)];
    return data;
  }

  get isModeCreate(): boolean {
    return this.$route.meta.mode === Mode.CREATE;
  }

  get getBaseUrl(): string {
    return fileServices.baseUrl();
  }

  get appPreference(): ResponsePreference[] {
    return this.$store.state.preferenceStore.appPreference;
  }

  get isInvoiceAR(): boolean {
    return this.receiptArForm.invoiceType === InvoiceType.INVOICE_AR;
  }

  get isInvoicePrepayment(): boolean {
    return this.receiptArForm.invoiceType === InvoiceType.PREPAYMENT;
  }

  get hasPrivilegeInvoiceReceipt(): boolean {
    return !!this.$store.getters["authStore/GET_USER_PRIVILEGES"].find(x => x.key === "invoice-receipt" && x.privilege.create && x.privilege.update);
  }

  get hasPrivilegeApprovalReceipt(): boolean {
    return !!this.$store.getters["authStore/GET_USER_PRIVILEGES"].find(x => x.key === "approval-invoice-receipt" && x.privilege.create && x.privilege.update);
  }

  get total() {
    return {
      invoice: this.invoiceSource.reduce(
        (prev, curr) => prev + +(curr.invoiceAmount || 0),
        0
      ),
      paid: this.invoiceSource.reduce(
        (prev, curr) => prev + (curr.paidAmount || 0),
        0
      ),
      writeOff: this.invoiceSource.reduce((prev, curr) => {
        return new Decimal(curr.writeOffAmountTotal ?? 0).plus(prev).toNumber();
      }, 0),
      outstandingInvoice: this.invoiceSource.reduce(
        (prev, curr) => new Decimal(curr.invoiceOutstanding ?? 0).plus(prev).toNumber(),
        0
      ),
    };
  }

  set total({ invoice, paid, writeOff, outstandingInvoice }) {
    this.writeOff = writeOff;
  }

  get statusDocument(): {new, submitted, posted, void, approved, updated, canceled} {
    return {
      new: this.receiptArForm.status === STATUS.NEW,
      submitted: this.receiptArForm.status === STATUS.SUBMITTED,
      posted: this.receiptArForm.status === STATUS.POSTED,
      void: this.receiptArForm.status === STATUS.VOID,
      approved: this.receiptArForm.status === STATUS.APPROVED,
      updated: this.receiptArForm.status === STATUS.UPDATED,
      canceled: this.receiptArForm.status === STATUS.CANCELED,
    };
  }

  handleChangeCustomer({ value, meta }: { value: string, meta: IOption<ContactData> }): void {
    if (value) {
      this.disable.addRowBtn = false;
      this.receiptArForm.receiptType = RECEIPT_TYPE.APPLIED;
      this.customerBillAddress = meta.meta?.addressDataList || [];
      const billAddress = meta.meta?.addressDataList.find(x => x.billTo && x.primaryBillTo);
      if (billAddress) {
        this.receiptArForm.customerBillToAddress = `${billAddress.address}, ${billAddress.cityDistrict}, ${billAddress.country}, ${billAddress.postalCode}`;
      }
      this.formRules.customerBillToAddress = [
        {
          required: true,
          message: (): string => this.$t("lbl_validation_required_error").toString(),
        },
      ];
    } else {
      this.disable.addRowBtn = true;
      this.receiptArForm.receiptType = RECEIPT_TYPE.UNINDENTIFIED;
      this.formRules.customerBillToAddress = [
        {
          required: false,
          message: (): string => this.$t("lbl_validation_required_error").toString(),
        },
      ];
      this.invoiceSource = [];
    }
  }

  handleCurrencyChange({ meta }: { meta: ResponseAccountingCurrency }): void {
    this.currencyFrom = meta.currencyCode;
    if (meta.currencyCode === "IDR") {
      this.showFields.rate = false;
      this.receiptArForm.currencyRate = 0;
      this.formRules.currencyRate = [
        {
          required: false,
          message: (): string => this.$t("lbl_validation_required_error").toString(),
        },
      ];
    } else {
      this.showFields.rate = true;
      this.formRules.currencyRate = [
        {
          required: true,
          message: (): string => this.$t("lbl_validation_required_error").toString(),
        },
      ];
    }
  }

  handleChangeInvoiceType(): void {
    // this.getInvoiceAr();
  }

  checkIsUndefinedAndReturnString(data): string {
    if(data) return data;
    else return "";
  }

  async getArReceiptDetail(): Promise<void> {
    try {
      this.loadingIndicator.data = true;
      const res = await arService.getDetailArReceipt(this.id);
      this.disable.update = DISALLOW_EDIT.includes(res.status);
      this.disable.form = DISALLOW_EDIT.includes(res.status);
      if(res.currency !== "IDR") {
        this.showFields.rate = true;
        this.formRules.currencyRate = [
          {
            required: true,
            message: (): string => this.$t("lbl_validation_required_error").toString(),
          },
        ];
      }

      // Assign form value
      this.arReceiptDetail = res;

      /**
       * commented for backup
       * this block of code is assigning
       * option credit memo by using invoice
       * prepayment id

      const findColumn = this.columnsTable.find(
        (c) => c.responsiveColSelect
      )?.responsiveColSelect;
      assign options invoice credit memo
      res.receiptLines.forEach((dataForeach) => {
        const temp = dataForeach.applyPrepayment ?
          dataForeach.applyPrepayment.prepaymentLines.map((dataMap) => {
            return {
              ...dataMap,
              name: dataMap.invoicePrepaymentNo,
              id: this.checkIsUndefinedAndReturnString(dataMap.invoicePrepaymentId),
              remainingAmount: dataMap.appliedAmount
            };
          }) : [];

        if (findColumn) {
          findColumn[1].options.push(...temp);
          // cek duplicate
          findColumn[1].options = findColumn[1].options.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            ))
          );
        }
      });
      */

      for (const key in this.receiptArForm) {
        if (Object.prototype.hasOwnProperty.call(this.receiptArForm, key)) {
          this.receiptArForm[key] = res[key];
        }
      }
      this.receiptArForm.receiptDate = res.receiptDate;
      if (res.chequeDate) {
        this.receiptArForm.chequeDate = res.chequeDate;
      }
      this.receiptArForm.receiptAmount = res.receiptAmount;
      this.receiptArForm.currencyRate = res.currencyRate;
      this.customerName = res.customerName;

      /**
       * assign lines to tables
       * move to below code.
       * no need to format currency when
       * doing interation.
       * currency formating will apply on view

      res.receiptLines.forEach((r, idx) => {
        r.key = idx;
        r.writeOfAmount = currencyFormat(r.writeOfAmount)
        r.receiptAmount = currencyFormat(r.receiptAmount)
      });
      */

      /**
       * map receipt lines to table
       */
      this.invoiceSource = res.receiptLines.map((dataMap, idx) => {
        return { ...dataMap,
          no: idx + 1,
          key: idx,
          invoiceCreditMemo: this.checkIsUndefinedAndReturnString(dataMap.applyPrepayment?.prepaymentLines[0]?.invoicePrepaymentId),
          idApplyPrepayment: this.checkIsUndefinedAndReturnString(dataMap.applyPrepayment?.prepaymentLines[0]?.id),
          invoicePrepaymentId: this.checkIsUndefinedAndReturnString(dataMap.invoicePrepaymentId),
          writeOffAmountTotal: this.calcTotalWriteOff(dataMap.writeOffList),
          writeOffRequestDTOS: dataMap.writeOffList,
        };
      });

      // Assign journal list
      this.arReceiptDetail.journals = [
        {
          number: res.journalNo,
          id: res.journalId
        }
      ];
      // this.arReceiptDetail.journals = res?.journalId?.map((j, idx) => {
      //   return {
      //     number: res.journalNo[idx],
      //     id: j,
      //   };
      // });

      /**
       * fetch image from filename
       */
      this.receiptArForm.fileAttachment.forEach(x => {
        this.fetchImage(x, (e) => this.linkAttachment.push({thumbnail: e, filename: x}));
      });

      this.setEditableFields();

      /**
       * commented for backup
       * (?) after fetch detail
       * need to call this function

      this.handleChangeInvoiceType();
       */
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingIndicator.data = false;
    }
  }

  calcTotalWriteOff(writeOff): number {
    return writeOff.reduce((a, b) => new Decimal(b.amount ?? 0).plus(a).toNumber(), 0);
  }

  async fetchImage(fileName: string, callback: (e) => void): Promise<void> {
    try {
      const res = await fileServices.getFile(fileName);
      const url = await transformDataURL(res);
      callback(url);
    } catch (error) {
      this.showErrorMessage("notif_process_fail");
    }
  }

  setEditableFields(): void {
    if (this.arReceiptDetail.customerId) {
      this.disable.addRowBtn = false;
    } else {
      this.disable.addRowBtn = true;
    }

    if (
      this.arReceiptDetail.receiptType.toUpperCase() ===
      RECEIPT_TYPE.APPLIED.toUpperCase()
    ) {
      this.disable.editableField = true;
      const actionColumn = this.columnsTable.findIndex(
        (c) => c.dataIndex === "operation"
      );
      if (actionColumn >= 0) {
        this.columnsTable.splice(actionColumn, 1);
      }
      this.invoiceSource.forEach((r) => {
        r.disabledSelect = true;
        r.disabledInput = true;
      });
    } else {
      this.disable.editableField = false;
    }
  }

  /**
   * removed this is not necessary
   * commented for backup
   */
  getInvoiceAr(docNumber?: string): void {
    // switch(this.receiptArForm.invoiceType) {
    //   case InvoiceType.INVOICE_AR:
    //     this.getInvoiceArList(docNumber);
    //     this.getInvoiceCreditMemo(docNumber);
    //     break;
    //   case InvoiceType.CREDIT_MEMO:
    //     this.getInvoiceCreditMemo(docNumber);
    //     break;
    //   case InvoiceType.PREPAYMENT:
    //     this.getInvoicePrepayment(docNumber);
    //     break;
    //   default:
    //     break;
    // }
  }

  getOutstandingInvoice(value: unknown, row: IReceiptLine): {children: number} {
    return {
      children: this.calculateOutstandingInvoice(
        row.invoiceAmount || 0,
        row.paidAmount || 0,
        row.creditAmount || 0
      )
    };
  }

  /**
   * @param invoiceAmount invoice amount
   * @param paidAmount paid amount
   * @param creditAmount credit amount
   *
   * formula = invoice amount - paid amount - credit amount
   */
  calculateOutstandingInvoice(
    invoiceAmount: number,
    paidAmount: number,
    creditAmount: number
  ): number {
    let outstandingAmount = new Decimal(invoiceAmount ?? 0).minus(paidAmount ?? 0).minus(creditAmount ?? 0).toNumber();
    if (outstandingAmount < 0) {
      outstandingAmount = 0;
    }
    return outstandingAmount;
  }

  handleApply(): void {
    this.formRules.customerId = [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ];
    this.formRules.customerBillToAddress = [
      {
        required: true,
        message: (): string => this.$t("lbl_validation_required_error").toString(),
      },
    ];
    this.handleSubmit();
  }

  beforeUpload(file): boolean {
    const isLt5M = file.size <= MAXIMUM_FILE_SIZE;
    if (!isLt5M) {
      this.showErrorMessage("lbl_upload_info_1");
    }
    return isLt5M;
  }

  handleAttachmentChange(info): void {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.status === "done") {
        if (file.response) {
          // Component will show file.url as link
          // file.url = file.response.url;

          file.url = file.response.objectName;
        }
      }
      return file;
    });

    this.fileList = fileList;
  }

  removeFile(file): void {
    this.fileList = this.fileList.filter((f) => f.url !== file.url);
  }

  clickJournal(id: string): void {
    if (id) this.$router.push("/generaljournal/journal/detail/" + id);
  }

  checkError(): boolean {
    try {
      // Check whether there is empty receipt amount in table
      const emptyReceiptAmountInvoice = this.invoiceSource.find(
        (d) => !d.receiptAmount
      );
      if (emptyReceiptAmountInvoice) {
        this.showNotifError("lbl_notif_required_receipt_amount", {invoice: emptyReceiptAmountInvoice.invoiceNumber});
        throw new Error("Empty receipt amount");
      }

      // Check whether invoiceSource is empty when receiptType is Applied, if yes it will throw an error
      if (
        this.invoiceSource.length <= 0 &&
        this.receiptArForm.receiptType === RECEIPT_TYPE.APPLIED
      ) {
        this.showNotifError("notif_empty_invoice_source");
        throw new Error("Empty invoices");
      }

      // Check whether receipt date of invoiceSource is before invoice date that user inputs in header form
      const invalidateInvoice = this.invoiceSource.find((d) =>
        moment(this.receiptArForm.receiptDate).isBefore(
          moment(d.invoiceDate),
          "days"
        )
      );
      if (invalidateInvoice) {
        this.showNotifError("notif_invalidate_invoice_source");
        throw new Error("Invalid receipt date");
      }

      const totalReceiptAmount = this.invoiceSource.reduce(
        (prev, curr) => prev + curr.receiptAmount,
        0
      );

      // Check total receipt amount in invoice list < receipt amount in main form
      if (
        totalReceiptAmount >
        this.receiptArForm.receiptAmount
      ) {
        this.showNotifError("lbl_notif_overbilled_receipt");
        throw new Error("Total invoice amount is greater than receipt amount");
      }
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
