import { Api } from "@/models/class/api.class";
import {
  IArDataList,
  IArDataPayload,
  IArDataResponse,
} from "@interface/account-receivables.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "@/services/http.service";

export class AccountReceivableService extends HttpClient {

  downloadListArReceipt = (param: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.ArReceipt + "/download/report", { params: param, responseType: "arraybuffer" })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createArReceipt = (data: IArDataPayload): Promise<IArDataResponse> => {
    return this.post<IArDataResponse, IArDataPayload>(Api.ArReceipt, data)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  
  approveArReceipt = (data: IArDataPayload, id: string): Promise<IArDataResponse> => {
    return this.post<IArDataResponse, IArDataPayload>(`${Api.ArReceipt}/approve/${id}`, data)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  cancelArReceipt = (data: IArDataPayload, id: string): Promise<IArDataResponse> => {
    return this.post<IArDataResponse, IArDataPayload>(`${Api.ArReceipt}/reject/${id}`, data)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  voidArReceipt = (data: IArDataPayload, id: string): Promise<IArDataResponse> => {
    return this.post<IArDataResponse, IArDataPayload>(`${Api.ArReceipt}/void/${id}`, data)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateArReceipt = (
    data: IArDataPayload,
    id: string
  ): Promise<IArDataResponse> => {
    return this.put<IArDataResponse, IArDataPayload>(
      `${Api.ArReceipt}/${id}`,
      data
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailArReceipt = (id: string): Promise<IArDataResponse> => {
    return this.get<IArDataResponse>(`${Api.ArReceipt}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListArReceipt = (param: RequestQueryParamsModel): Promise<IArDataList> => {
    return this.get<IArDataList>(Api.ArReceipt, { params: param })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  submitArReceipt = (payload: IArDataPayload, id: string): Promise<IArDataResponse> => {
    return this.post<IArDataResponse, IArDataPayload>(`${Api.ArReceipt}/submit/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * Apply an AR Receipt when the receipt type is Unindentified
   * @param data data payload
   * @param id ar receipt id
   * @returns Promise
   */
  applyArReceipt = (
    data: IArDataPayload,
    id: string
  ): Promise<IArDataResponse> => {
    return this.post<IArDataResponse, IArDataPayload>(
      `${Api.ArReceipt}/submit/${id}`,
      data
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}

export const arService = new AccountReceivableService();
