var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_account_receivable_receipt") } },
        [
          _c("span", { attrs: { slot: "extra" }, slot: "extra" }, [
            _vm._v(_vm._s(_vm.receiptArForm.status || "-"))
          ]),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.receiptArForm,
                        rules: _vm.formRules,
                        layout: "vertical"
                      }
                    },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16], type: "flex" } },
                        [
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_branch"),
                                    prop: "branchWarehouseId"
                                  }
                                },
                                [
                                  _c("CSelectBranch", {
                                    attrs: { "is-disabled": _vm.disable.form },
                                    model: {
                                      value:
                                        _vm.receiptArForm.branchWarehouseId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.receiptArForm,
                                          "branchWarehouseId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receiptArForm.branchWarehouseId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_transaction_type"),
                                    prop: "receiptType"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "lbl_" +
                                            _vm.receiptArForm.receiptType.toLowerCase()
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "lbl_account_recievable_number"
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.arReceiptDetail.documentNumber ||
                                          "-"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_invoice_type"),
                                    prop: "invoiceType"
                                  }
                                },
                                [
                                  _c("CSelectInvoiceARType", {
                                    attrs: {
                                      disabled: _vm.disable.form,
                                      "disabled-option": ["Credit Memo"]
                                    },
                                    model: {
                                      value: _vm.receiptArForm.invoiceType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.receiptArForm,
                                          "invoiceType",
                                          $$v
                                        )
                                      },
                                      expression: "receiptArForm.invoiceType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    prop: "currencyId",
                                    label: _vm.$t("lbl_currency")
                                  }
                                },
                                [
                                  _c("CSelectCurrency", {
                                    attrs: {
                                      disabled: _vm.disable.form,
                                      "prop-default": _vm.isModeCreate
                                    },
                                    on: {
                                      "on-select": _vm.handleCurrencyChange
                                    },
                                    model: {
                                      value: _vm.receiptArForm.currencyId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.receiptArForm,
                                          "currencyId",
                                          $$v
                                        )
                                      },
                                      expression: "receiptArForm.currencyId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.showFields.rate
                            ? _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        prop: "currencyRate",
                                        label: _vm.$t("lbl_rate")
                                      }
                                    },
                                    [
                                      _c("a-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: _vm.disable.form,
                                          min: 0,
                                          formatter: _vm.formatterNumber,
                                          parser: _vm.reverseFormatNumber
                                        },
                                        model: {
                                          value: _vm.receiptArForm.currencyRate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.receiptArForm,
                                              "currencyRate",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "receiptArForm.currencyRate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    prop: "receiptAmount",
                                    label: _vm.$t("lbl_receipt_amount")
                                  }
                                },
                                [
                                  _c("a-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled:
                                        _vm.disable.update || _vm.disable.form,
                                      min: 0,
                                      formatter: _vm.formatterNumber,
                                      parser: _vm.reverseFormatNumber
                                    },
                                    model: {
                                      value: _vm.receiptArForm.receiptAmount,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.receiptArForm,
                                          "receiptAmount",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "receiptArForm.receiptAmount"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_receipt_date"),
                                    prop: "receiptDate"
                                  }
                                },
                                [
                                  _c("a-date-picker", {
                                    staticClass: "w-100",
                                    attrs: {
                                      placeholder: _vm.$t("lbl_choose"),
                                      format: _vm.DEFAULT_DATE_FORMAT,
                                      disabled: _vm.disable.form
                                    },
                                    model: {
                                      value: _vm.receiptArForm.receiptDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.receiptArForm,
                                          "receiptDate",
                                          $$v
                                        )
                                      },
                                      expression: "receiptArForm.receiptDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    prop: "customerId",
                                    label: _vm.$t("lbl_customer_name")
                                  }
                                },
                                [
                                  _c("CSelectCustomer", {
                                    attrs: {
                                      disabled: _vm.disable.editableField,
                                      "for-list": false,
                                      "prop-customer-name":
                                        _vm.receiptArForm.customerId
                                    },
                                    on: {
                                      "on-select": _vm.handleChangeCustomer,
                                      "on-clear": _vm.handleChangeCustomer
                                    },
                                    model: {
                                      value: _vm.receiptArForm.customerId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.receiptArForm,
                                          "customerId",
                                          $$v
                                        )
                                      },
                                      expression: "receiptArForm.customerId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    prop: "customerBillToAddress",
                                    label: _vm.$t("lbl_customer_bill_address")
                                  }
                                },
                                [
                                  _c("CSelectBillAddress", {
                                    attrs: {
                                      disabled: _vm.disable.editableField,
                                      "prop-address": _vm.customerBillAddress
                                    },
                                    model: {
                                      value:
                                        _vm.receiptArForm.customerBillToAddress,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.receiptArForm,
                                          "customerBillToAddress",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receiptArForm.customerBillToAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_receipt_method"),
                                    prop: "receiptMethod"
                                  }
                                },
                                [
                                  _c("CSelectInvoiceReceiptMethod", {
                                    attrs: { disabled: _vm.disable.form },
                                    on: {
                                      "on-select": _vm.handleReceiptMethodChange
                                    },
                                    model: {
                                      value: _vm.receiptArForm.receiptMethod,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.receiptArForm,
                                          "receiptMethod",
                                          $$v
                                        )
                                      },
                                      expression: "receiptArForm.receiptMethod"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.showFields.cheque
                            ? _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("lbl_cheque_date"),
                                        prop: "chequeDate"
                                      }
                                    },
                                    [
                                      _c("a-date-picker", {
                                        staticClass: "w-100",
                                        attrs: {
                                          placeholder: _vm.$t("lbl_choose"),
                                          format: _vm.DEFAULT_DATE_FORMAT,
                                          disabled: _vm.disable.form,
                                          "disabled-date": _vm.disabledDate
                                        },
                                        model: {
                                          value: _vm.receiptArForm.chequeDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.receiptArForm,
                                              "chequeDate",
                                              $$v
                                            )
                                          },
                                          expression: "receiptArForm.chequeDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showFields.cheque
                            ? _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("lbl_cheque_number"),
                                        prop: "chequeNumber"
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: { disabled: _vm.disable.form },
                                        model: {
                                          value: _vm.receiptArForm.chequeNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.receiptArForm,
                                              "chequeNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "receiptArForm.chequeNumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showFields.cheque
                            ? _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("lbl_bank_customer_name"),
                                        prop: "customerBankName"
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        model: {
                                          value:
                                            _vm.receiptArForm.customerBankName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.receiptArForm,
                                              "customerBankName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "receiptArForm.customerBankName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    prop: "bankAccountId",
                                    label: _vm.$t("lbl_bank_account_name")
                                  }
                                },
                                [
                                  _c("CSelectCompanyBank", {
                                    attrs: { disabled: _vm.disable.form },
                                    model: {
                                      value: _vm.receiptArForm.bankAccountId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.receiptArForm,
                                          "bankAccountId",
                                          $$v
                                        )
                                      },
                                      expression: "receiptArForm.bankAccountId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_journal_number"),
                                    prop: "journalNumber"
                                  }
                                },
                                [
                                  !_vm.arReceiptDetail.journals
                                    ? _c("span", [_vm._v("-")])
                                    : _c(
                                        "ul",
                                        { staticClass: "list-unstyled" },
                                        _vm._l(
                                          _vm.arReceiptDetail.journals,
                                          function(item, index) {
                                            return _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass: "mb-2"
                                              },
                                              [
                                                _c(
                                                  "a-button",
                                                  {
                                                    attrs: {
                                                      disabled: !item.number,
                                                      type: "dashed"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.clickJournal(
                                                          item.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.number || "-"
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_attachment"),
                                    prop: "attachment"
                                  }
                                },
                                [
                                  !_vm.disable.editableField
                                    ? _c(
                                        "a-upload",
                                        {
                                          attrs: {
                                            name: "file",
                                            action: _vm.getBaseUrl,
                                            headers: _vm.headers,
                                            multiple: true,
                                            accept: _vm.ALLOWED_FILE_TYPES,
                                            "before-upload": _vm.beforeUpload,
                                            "file-list": _vm.fileList,
                                            remove: _vm.removeFile
                                          },
                                          on: {
                                            change: _vm.handleAttachmentChange
                                          }
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            { attrs: { icon: "upload" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_upload")) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.id
                                    ? _c(
                                        "div",
                                        {
                                          class: _vm.disable.editableField
                                            ? ""
                                            : "mt-3"
                                        },
                                        [
                                          _vm.arReceiptDetail.fileAttachment &&
                                          _vm.arReceiptDetail.fileAttachment
                                            .length > 0
                                            ? _c(
                                                "ol",
                                                _vm._l(
                                                  _vm.linkAttachment,
                                                  function(item, index) {
                                                    return _c(
                                                      "li",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                item.thumbnail,
                                                              target: "_blank",
                                                              rel:
                                                                "noopener noreferrer",
                                                              download:
                                                                "" +
                                                                item.filename
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.filename ||
                                                                    "-"
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c(
                                                "span",
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "file" }
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_file_not_found"
                                                        )
                                                      )
                                                  )
                                                ],
                                                1
                                              )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tabs",
            { attrs: { type: "card" } },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: _vm.$t("lbl_details") } },
                [
                  _c(
                    "a-row",
                    { attrs: { type: "flex", gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.disable.editableField ||
                                  _vm.disable.addRowBtn,
                                type: "primary",
                                icon: "plus"
                              },
                              on: { click: _vm.handleAddRow }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16], type: "flex" } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-table",
                            {
                              attrs: {
                                "data-source": _vm.invoiceSource,
                                pagination: {
                                  showTotal: function(total) {
                                    return _vm.$t("lbl_total_items", {
                                      total: total
                                    })
                                  }
                                },
                                scroll: { x: "calc(1300px + 50%)", y: 500 }
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "footer" },
                                [
                                  _c(
                                    "a-row",
                                    {
                                      staticClass:
                                        "flex-column align-items-md-end",
                                      attrs: { type: "flex" }
                                    },
                                    [
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            xs: { span: 24 },
                                            lg: { span: 10 }
                                          }
                                        },
                                        [
                                          _c(
                                            "table",
                                            { staticClass: "footer-table" },
                                            [
                                              _c("tr", [
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_total_invoice_amount"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          _vm.total.invoice
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ]),
                                              _c("tr", [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "lbl_total_paid_amount"
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          _vm.total.paid
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ]),
                                              _c("tr", [
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_total_write_off_account"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          _vm.total.writeOff
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ]),
                                              _c("tr", [
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_total_invoice_outstanding"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          _vm.total
                                                            .outstandingInvoice
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-table-column",
                                {
                                  key: "no",
                                  attrs: { "data-index": "no", width: "75px" }
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [_vm._v(_vm._s(_vm.$t("lbl_number_short")))]
                                  )
                                ]
                              ),
                              _c(
                                "a-table-column",
                                {
                                  key: "invoiceNumber",
                                  attrs: { "data-index": "invoiceNumber" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(text, record) {
                                        return _c("CSelectInvoice", {
                                          attrs: {
                                            "receipt-ar-form":
                                              _vm.receiptArForm,
                                            disabled: _vm.disable.form
                                          },
                                          on: {
                                            "on-select": function(ref) {
                                              var value = ref.value
                                              var meta = ref.meta

                                              return _vm.onSelectInvoiceNumber(
                                                value,
                                                meta,
                                                record
                                              )
                                            }
                                          },
                                          model: {
                                            value: record.invoiceNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                record,
                                                "invoiceNumber",
                                                $$v
                                              )
                                            },
                                            expression: "record.invoiceNumber"
                                          }
                                        })
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("lbl_invoice_number"))
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "a-table-column",
                                {
                                  key: "invoiceDate",
                                  attrs: { "data-index": "invoiceDate" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(_vm._s(_vm._f("date")(text)))
                                        ])
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [_vm._v(_vm._s(_vm.$t("lbl_invoice_date")))]
                                  )
                                ]
                              ),
                              _c(
                                "a-table-column",
                                {
                                  key: "invoiceAmount",
                                  attrs: { "data-index": "invoiceAmount" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(
                                            _vm._s(_vm._f("currency")(text))
                                          )
                                        ])
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("lbl_invoice_amount"))
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "a-table-column",
                                {
                                  key: "paidAmount",
                                  attrs: { "data-index": "paidAmount" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(
                                            _vm._s(_vm._f("currency")(text))
                                          )
                                        ])
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [_vm._v(_vm._s(_vm.$t("lbl_paid_amount")))]
                                  )
                                ]
                              ),
                              _c(
                                "a-table-column",
                                {
                                  key: "writeOffAmountTotal",
                                  attrs: {
                                    "data-index": "writeOffAmountTotal"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(text, record) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  record.writeOffAmountTotal
                                                )
                                              )
                                            )
                                          ]),
                                          _c(
                                            "a-button",
                                            {
                                              staticClass: "ml-3",
                                              attrs: {
                                                type: "dashed",
                                                size: "small"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showWriteoffDetail(
                                                    record
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_detail")) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("lbl_write_off_amount"))
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "a-table-column",
                                {
                                  key: "invoiceOutstanding",
                                  attrs: { "data-index": "invoiceOutstanding" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(
                                            _vm._s(_vm._f("currency")(text))
                                          )
                                        ])
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("lbl_invoice_outstanding")
                                        )
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm.isInvoiceAR
                                ? _c(
                                    "a-table-column",
                                    {
                                      key: "invoiceCreditMemo",
                                      attrs: {
                                        "data-index": "invoiceCreditMemo"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(text, record) {
                                              return _c(
                                                "CSelectInvoiceCreditMemo",
                                                {
                                                  attrs: {
                                                    disabled: _vm.disable.form,
                                                    "receipt-ar-form":
                                                      _vm.receiptArForm,
                                                    "ar-receipt-detail":
                                                      _vm.arReceiptDetail
                                                  },
                                                  on: {
                                                    "on-select": function(ref) {
                                                      var value = ref.value
                                                      var meta = ref.meta

                                                      return _vm.onSelectCreditMemo(
                                                        value,
                                                        meta,
                                                        record
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      record.invoiceCreditMemo,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        record,
                                                        "invoiceCreditMemo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "record.invoiceCreditMemo"
                                                  }
                                                }
                                              )
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2407837840
                                      )
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "title" },
                                          slot: "title"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("lbl_invoice_credit_memo")
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "a-table-column",
                                {
                                  key: "creditAmount",
                                  attrs: { "data-index": "creditAmount" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(text) {
                                        return _c("span", {}, [
                                          _vm._v(
                                            _vm._s(_vm._f("currency")(text))
                                          )
                                        ])
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("lbl_credit_amount"))
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "a-table-column",
                                {
                                  key: "receiptAmount",
                                  attrs: { "data-index": "receiptAmount" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(text, record) {
                                        return _c("a-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            parser: _vm.reverseFormatNumber,
                                            formatter: _vm.formatterNumber,
                                            readonly: _vm.disable.form,
                                            min: 0
                                          },
                                          model: {
                                            value: record.receiptAmount,
                                            callback: function($$v) {
                                              _vm.$set(
                                                record,
                                                "receiptAmount",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "record.receiptAmount"
                                          }
                                        })
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("lbl_receipt_amount"))
                                      )
                                    ]
                                  )
                                ]
                              ),
                              !_vm.disable.form
                                ? _c(
                                    "a-table-column",
                                    {
                                      key: "action",
                                      attrs: { align: "center", width: 100 },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(text, record) {
                                              return _c("a-icon", {
                                                staticClass: "icon-delete",
                                                attrs: {
                                                  type: "delete",
                                                  theme: "filled"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.responseDeleteRow(
                                                      record
                                                    )
                                                  }
                                                }
                                              })
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3338185431
                                      )
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "title" },
                                          slot: "title"
                                        },
                                        [_vm._v(_vm._s(_vm.$t("lbl_action")))]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          _vm.hasPrivilegeInvoiceReceipt
            ? _c(
                "a-row",
                { attrs: { type: "flex", gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24, align: "end" } },
                    [
                      _c(
                        "a-space",
                        [
                          _c("a-button", { on: { click: _vm.handleBack } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                          ]),
                          _vm.statusDocument.approved
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    loading: _vm.loadingIndicator.void
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit("Void")
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("lbl_void")) + " ")]
                              )
                            : _vm._e(),
                          _vm.statusDocument.submitted &&
                          _vm.hasPrivilegeApprovalReceipt
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    icon: "close",
                                    loading: _vm.loadingIndicator.canceled
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit("Canceled")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_reject")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.statusDocument.submitted &&
                          _vm.hasPrivilegeApprovalReceipt
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "check",
                                    loading: _vm.loadingIndicator.approved
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit("Approved")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_approve")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.statusDocument.submitted
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loadingIndicator.updated
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSubmit("Updated")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_update")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          !_vm.id
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    disabled: _vm.disable.form,
                                    type: "primary",
                                    icon: "save"
                                  },
                                  on: { click: _vm.handleSubmit }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_submit")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.id && !_vm.disable.editableField
                            ? _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleApply }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_apply")) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            "mask-closable": false,
            width: 800,
            title: "Invoice Credit Memo",
            showmodal: _vm.modalOpen
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c("TableCustom", {
                attrs: {
                  idtable: "table2",
                  "data-source": _vm.dataSourceModal,
                  columns: _vm.columnsTableModal,
                  scroll: { x: "calc(100px + 50%)" },
                  paginationcustom: false,
                  "default-pagination": true
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
              ]),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: { click: _vm.saveDataModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ]
      ),
      _c("ModalWriteOff", {
        attrs: {
          "prop-data-writeoff": _vm.modal.writeOff.data,
          "prop-status": _vm.arReceiptDetail.status
        },
        on: { "on-save": _vm.onSaveWriteOff },
        model: {
          value: _vm.modal.writeOff.show,
          callback: function($$v) {
            _vm.$set(_vm.modal.writeOff, "show", $$v)
          },
          expression: "modal.writeOff.show"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }